<template>
  <div>
    <fieldset style="width:97%">
      <legend>搜索信息</legend>
      <el-row :gutter="20">
        <el-col :span="4">
          <el-input v-model="form.clothesName" placeholder="款式编号"></el-input>
        </el-col>
        <el-col :span="3">
          <el-select v-model="form.styleType" filterable placeholder="款式类型" clearable class="searchParam" size="medium">
            <el-option
                v-for="item in styleTypeIdArray"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="6">
          <el-date-picker
              v-model="form.date"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              type="daterange"
              start-placeholder="档期开始日期"
              end-placeholder="档期结束日期">
          </el-date-picker>
        </el-col>

      </el-row>
      <br>
      <el-row>
        <el-col :span="4">
          <el-select v-model="form.shopId" placeholder="选择店铺" clearable @change="shopChange">
            <el-option
                v-for="item in shopIdArray"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-select v-model="form.positionId" placeholder="选择位置" clearable>
            <el-option
                v-for="item in positionIdArray"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-button type="primary" @click="search">搜索</el-button>
        </el-col>
      </el-row>
    </fieldset>
    <br>
    <el-table
        :data="table.tableList"
        v-loading="loading"
        border
        height="700"
        max-height="700"
        style="width: 100%">
      <el-table-column prop="styleAllName" label="款式名称" align="center"></el-table-column>
      <el-table-column prop="sameCount" label="相同衣服数" align="center" ></el-table-column>
      <el-table-column prop="scheduleCount" label="总档期数" align="center" ></el-table-column>
      <el-table-column prop="scheduleCountDay" label="总档期天数" align="center" ></el-table-column>

      <el-table-column width="350" align="center" fixed="right" label="操作">
        <template slot-scope="scope">
          <el-button-group>
            <el-button type="primary" size="mini" @click="openStyleSame(scope.row.styleId)">相同款式</el-button>
            <el-button type="success" size="mini" @click="queryStyleImageList(scope.row.styleId)">查看图片</el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>
    <el-col style="margin-top: 5px">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-size="this.table.limit"
          layout="prev, pager, next,total, jumper, sizes"
          :total="table.total">
      </el-pagination>
    </el-col>
    <clothesScheduleSame v-if="samePageState" :searchDate="form"  :state="samePageState" @close="close"/>
  </div>
</template>

<script>
import clothesScheduleSame from "@/pages/statistic/clothes-analysis/clothes-schedule-same";

export default {
name: "clothes-schedule-page",
  data() {
    return {
      form:{
        dateString:"",
        clothesName:"",
        date:"",
        shopId:"",
        positionId:"",
        tenantCrop: localStorage.getItem("tenantCrop")
      },
      shopIdArray:[],
      positionIdArray:[],
      styleTypeIdArray:[],
      loading:true,
      table:{
        tableList:[],
        page:1,
        limit:100,
        total:0,
      },
      samePageState:false,
    }
  },
  created() {
    this.queryShopIdArr();
    this.queryStyleList();
    this.queryStyleTypeIdsArr();
  },
  components:{clothesScheduleSame},
  methods: {
    search(){
      this.form.dateString=this.$dateUtils.stringArrayToString(this.form.date)
      this.queryStyleList()
    },
    //查询店铺
    queryShopIdArr(){
      this.$selectUtils.shopIdsIsValid().then(response=>{
        this.shopIdArray=JSON.parse(response.data.data)
      })
    },
    //店铺更改
    shopChange(val){
      if (val===""){
        this.positionIdArray=[];
        this.form.positionId="";
      }else {
        this.$selectUtils.queryPositionIdsByShopId(val).then(response=>{
          this.positionIdArray=JSON.parse(response.data.data)
        })
      }
    },
    //查询款式列表
    queryStyleList(){
      this.$axios({
        method: "GET",
        url: "/clothesAnalysis/queryClothesSchedule",
        params: {
          page: this.table.page,
          limit: this.table.limit,
          styleType: this.form.styleType,
          clothesName: "%"+this.form.clothesName+"%",
          date: this.form.dateString,
          positionId: this.form.positionId,
          shopId: this.form.shopId,
          tenantCrop: this.form.tenantCrop
        }
      }).then(response => {
        this.loading=false;
        this.table.tableList=response.data.data.list
        this.table.total=response.data.data.total;
      })
    },
    //打开相同款式
    openStyleSame(styleId){
      this.form.styleId=styleId
      this.samePageState=true;
    },
    //打开图片弹窗
    openStyleImage(){
      this.$viewerApi({
        images: this.styleImageList,
      })
    },
    //查询款式图片
    queryStyleImageList(styleId){
      const loadImage = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.$axios({
        method: "GET",
        url: "/styleImage/queryLinkListByStyleId",
        params: {
          styleId: styleId
        }
      }).then(response => {
        loadImage.close();
        this.$viewerApi({
          images: response.data.data
        })
      })
    },
    //查询款式类型
    queryStyleTypeIdsArr(){
      this.$selectUtils.queryStyleTypeIds().then(response=>{
        this.styleTypeIdArray=JSON.parse(response.data.data);
      })
    },
    //更改每页长度
    handleSizeChange:function (val){
      this.table.limit=val;
      this.queryStyleList();
    },
    handleCurrentChange(val) {
      this.table.page =val
      this.queryStyleList()
    },
    close: function () {
      this.samePageState=false;
    },
  }
}
</script>

<style scoped>

</style>