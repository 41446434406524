<template >
    <el-timeline style="margin: 0 5%;height: 100%" v-loading="loading" :reverse="true">
      <el-timeline-item v-for="item in clothesOperationList" :timestamp="item.createDate" placement="top"
                        :key="item.id" >
        <el-card>
          <h3>{{ item.operationName }}</h3><br>
          <p>婚纱编号&ensp;:&ensp;{{ item.clothesName }}</p><br>
          <p>原位置&ensp;:&ensp;{{ item.localShopName }}&ensp;{{ item.localPositionName }}</p><br>
          <p>新位置&ensp;:&ensp;{{ item.shopName }}&ensp;{{ item.positionName }}</p><br>
          <p>经办人&ensp;:&ensp;{{ item.empName }}</p><br>
          <p>备注&ensp;:&ensp;{{ item.remark }}</p>
        </el-card>
      </el-timeline-item>
    </el-timeline>
</template>

<script>
/*
* 侧边弹出框 参考 clothes-schedule-same 页面的 el-drawer 使用
* */
export default {
  name: "clothes-operation-list",
  data() {
    return {
      loading:true,
      clothesOperationList: [],
    }
  },
  created: function () {
    this.queryClothesOperationList();
  },
  props: {
    clothesId: {
      type: String,
      required: true,
    }
  },
  methods: {
    queryClothesOperationList: function () {
      this.$axios.get('/clothesOperation/clothesOperationList', {
        params: {
          clothesId: this.clothesId,
          tenantCrop: localStorage.getItem("tenantCrop")
        }
      }).then(response => {
        this.clothesOperationList = response.data.data.list;
        this.loading=false
      })
    },
  }
}
</script>

<style scoped>

</style>