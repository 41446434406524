<template>
  <el-dialog
      title="该婚纱档期数"
      width="75%"
      :visible.sync="dialogState"
      append-to-body
      @close="close"
      top="2%"
      center>
    <el-row style="text-align: center !important;">
      <el-radio-group v-model="radio" @change="change" size="medium">
        <el-radio-button label="month">按月显示</el-radio-button>
        <el-radio-button label="year">按年显示</el-radio-button>
        <el-radio-button label="list">列表显示</el-radio-button>
      </el-radio-group>
    </el-row>
    <br>
    <div style="overflow-y: auto;height: 650px">
      <el-row v-show="flag==='month'" class="month">
        <el-calendar>
          <template slot="dateCell" slot-scope="{date, data}" class="calItem">
            <p class="dayItem" v-if="data.day.substr(-2) < 10">{{ data.day.substr(-1) }}</p>
            <p class="dayItem" v-else>{{ data.day.substr(-2) }}</p>
            <div v-if="scheduleArray.includes(data.day)">
              <ul v-for="item in scheduleDetailsArray[data.day]">
                <li :style="{color:item.includes('预约') ? '#33cc33': '#3366ff'}">
                  {{ item }}<span v-if="scheduleDetailsArray[data.day].length>=2" style="color: brown">撞挡</span>
                </li>
              </ul>
            </div>
          </template>
        </el-calendar>
      </el-row>
      <el-row v-show="flag==='year'" v-for="(month,index) in monthArray" class="year" >
        <el-col :span="6" v-for="(temp,key) in monthArray[index]">
          <el-calendar v-model="monthArray[index][key]">
            <template slot="dateCell" slot-scope="{date, data}" class="calItem">
              <div :class="scheduleArray.includes(data.day) ? 'is-selected' : ''">
                <p  class="dayItem" v-if="scheduleDetailsArray[data.day]===undefined">{{ data.day.substr(-2) < 10 ? data.day.substr(-1):data.day.substr(-2) }}</p>
                <p  class="dayItem" style="background-color:red;" v-else-if="scheduleDetailsArray[data.day].length>1">撞</p>
                <p  class="dayItem" :style="{backgroundColor:scheduleDetailsArray[data.day][0].includes('预约')?'#33cc33':'#3366ff'}" v-else>{{scheduleDetailsArray[data.day][0].includes("预约")?"预":"定"}}</p>
              </div>
            </template>
          </el-calendar>
        </el-col>
      </el-row>
      <el-row v-show="flag==='list'" class="year">
        <el-col :span="24">
          <el-table
              :data="scheduleTableList"
              border
              height="650"
              max-height="650"
              :default-sort="{prop: 'id', order: 'ascending'}"
              style="width: 100%">
            <el-table-column prop="cusName" label="客资名" align="center"/>
            <el-table-column prop="scheduleDate" label="档期时间" align="center"/>
            <el-table-column prop="scheduleState" label="档期状态" align="center">
              <template slot-scope="scope">
                <p :style="{color:scope.row.scheduleState==='撞档'?'red':'green'}">{{scope.row.scheduleState}}</p>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
    </div>

  </el-dialog>
</template>

<script>
//根据婚纱展示档期 日期 日历 列表
export default {
  name: "clothes-schedule-details",
  data() {
    return {
      radio: "month",
      flag: "month",
      monthArray: [],
      scheduleArray: [],
      scheduleDetailsArray: [],
      scheduleTableList: [],
      dialogState: this.state,
    }
  },
  created: function () {
    this.getMonthAmong();
    this.queryClothesScheduleDetails();
    this.queryClothesAndScheduleByClothesId();
  },
  props: {
    state: {
      type: Boolean,
      required: true,
      default: false,
    },
    clothesId: {
      type: String,
      required: true,
    }
  },
  methods: {
    change: function (val) {
      this.flag = val;
    },
    getMonthAmong: function () {
      let k = 0;
      for (let i = 0; i < 3; i++) {
        let temp = new Array();
        for (let j = 0; j < 4; j++) {
          temp.push(new Date().setMonth(new Date().getMonth() + k++));
        }
        this.monthArray.push(temp);
      }
    },
    queryClothesScheduleDetails: function () {
      this.$axios({
        methods: "get",
        url: "/schedule/clothesScheduleDetails",
        params: {
          clothesId: this.clothesId,
        }
      }).then(response => {
        this.scheduleDetailsArray = response.data.data;
        this.scheduleArray = Object.keys(response.data.data);
      });
    },
    //根据衣服查询档期列表
    queryClothesAndScheduleByClothesId() {
      this.$axios({
        methods: "get",
        url: "/schedule/clothesAndScheduleByClothesId",
        params: {
          clothesId: this.clothesId,
        }
      }).then(response => {
        this.scheduleTableList = response.data.data
      });
    },
    close: function () {
      this.$emit("close");
    },
  }
}
</script>

<style scoped>
/deep/ .year .el-calendar-table .el-calendar-day {
  height: 35px;
  line-height: 28px;
  pointer-events: none;
  border-style: none;
  text-align: center;
}

/deep/ .year .el-button-group {
  display: none;
}

/deep/ .year .el-calendar-table:not(.is-range) td.next,
.year .el-calendar-table:not(.is-range) td.prev {
  pointer-events: none;
}

/deep/ .year td {
  pointer-events: none;
}

/deep/ .year .el-calendar-table .is-selected {
  background-color: #409eff;
  border-radius: 50%;
  color: white;
}

/deep/ .year .el-calendar-table td.is-selected {
  background-color: white;
  color: black;
}

/deep/ .el-backtop, .el-calendar-table td.is-today {
  color: black;
}

/deep/ .year .el-calendar-table tr td:first-child {
  border-left: none;
}

/deep/ .year .el-calendar-table tr:first-child td {
  border-top: none;
}

/deep/ .year .el-calendar-table td {
  border-bottom: none;
  border-right: none;
}

/deep/ .dayItem {
  text-align: center;
}

.year /deep/ .el-calendar__button-group {
  display: none;
}

/deep/ .current {
  height: 25px;
}
</style>